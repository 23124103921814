<template>
    <form id="frm_action">
      <div class="p-fluid">
        <div class="p-col-12 p-lg-12 view-action">
          <div class="card card-w-title">
            <Panel>
              <template slot="header">
                  <h2>Thông tin hotline</h2>
              </template>
              <div class="p-formgrid">
                <div class="p-grid">
                  <div class="p-field p-col-6">
                      <label class="p-col-fixed txt-left">Trang chủ <span class="span-cus">*</span></label>
                      <div class="p-col">
                          <InputText v-model="home_phone" type="text" id="home" placeholder="Số điện thoại trang chủ"/>
                      </div>
                  </div>
                  <div class="p-field p-col-6">
                      <label class="p-col-fixed txt-left">Dịch vụ <span class="span-cus">*</span></label>
                      <div class="p-col">
                          <InputText v-model="service_phone" type="text" placeholder="Số điện thoại dịch vụ" id="service_add"/>
                      </div>
                  </div>
                  <div class="p-field p-col-6">
                      <label class="p-col-fixed txt-left">Câu lạc bộ <span class="span-cus">*</span></label>
                      <div class="p-col">
                          <InputText v-model="club_phone" type="text" id="club_add" placeholder="Số điện thoại clb"/>
                      </div>
                  </div>
                  <div class="p-field p-col-6">
                      <label class="p-col-fixed txt-left">Thông tin cá nhân <span class="span-cus">*</span></label>
                      <div class="p-col">
                          <InputText v-model="profile_phone" type="text" id="profile_add" placeholder="Số điện thoại thông tin cá nhân"/>
                      </div>
                  </div>
                  <div class="p-field p-col-6">
                      <label class="p-col-fixed txt-left">Tài khoản <span class="span-cus">*</span></label>
                      <div class="p-col">
                          <InputText v-model="account_phone" type="text" id="account_add" placeholder="Số điện thoại tài khoản"/>
                      </div>
                  </div>
                  <div class="p-field p-col-6">
                      <label class="p-col-fixed txt-left">Khuyến mãi <span class="span-cus">*</span></label>
                      <div class="p-col">
                          <InputText v-model="promo_phone" type="text" id="promo_add" placeholder="Số điện thoại khuyến mãi"/>
                      </div>
                  </div>
                </div>
              </div>
            </Panel>
            <Panel>
              <template slot="header">
                  <h2>Thông tin chung</h2>
              </template>
              <div class="p-formgrid">
                <div class="p-grid">
                  <div class="p-field p-col-6">
                      <label class="p-col-fixed txt-left">Fanpage</label>
                      <div class="p-col">
                          <InputText v-model="fanpage" type="text" id="fanpage" placeholder="Fanpage"/>
                      </div>
                  </div>
                  
                  <div class="p-field p-col-6">
                      <label class="p-col-fixed txt-left">Zalo</label>
                      <div class="p-col">
                          <InputText v-model="zalo" type="text" id="zalo" placeholder="Zalo"/>
                      </div>
                  </div>
                  
                  <div class="p-field p-col-6">
                      <label class="p-col-fixed txt-left">Messenger</label>
                      <div class="p-col">
                          <InputText v-model="messenger" type="text" id="messenger" placeholder="Messenger"/>
                      </div>
                  </div>
                  
                  <div class="p-field p-col-6">
                      <label class="p-col-fixed txt-left">WhatsApp</label>
                      <div class="p-col">
                          <InputText v-model="whatsapp" type="text" id="whatsapp" placeholder="WhatsApp"/>
                      </div>
                  </div>
                </div>
              </div>
            </Panel>
          </div>
        </div>
      </div>
    </form>
</template>

<script>
// import DataServices from '@/core/DataServices'
// import ApiRepository from "@/core/ApiRepository";
// import CkditorCore from '../CkditorCore';
export default {
  // components: {
  //   CkditorCore
  // },
  props: {
    mode: String,
    model: Object,
    users: Array,
    triggerSave: Boolean
  },
  data() {
    return {
      formMode: this.mode,
      trigger_save: this.triggerSave,
      name: '',
      ordinal: 0,
      images: [],
      models: {},
      deleteId: null,
      deleteName: null,
      showWarningDialog: false,
      pagination: true, // true cho phép phân trang
      paginationRows: 10, // số dòng trên 1 trang,
      listData: [],
      // dataModel: null,
      componentKey: 0,
      filters: {},
      maxFileSize: 2097152 , // 2MB   /*104857600, // 100MB*/
      phone: this.model.phone,
      messenger: this.model.messenger,
      whatsapp: this.model.whatsapp,
      zalo: this.model.zalo,
      fanpage: this.model.fanpage,
      home_phone: this.model.home_phone,
      service_phone: this.model.service_phone,
      club_phone: this.model.club_phone,
      profile_phone: this.model.profile_phone,
      account_phone: this.model.account_phone,
      promo_phone: this.model.promo_phone,
    }
  },
  watch: {
    'triggerSave'() {
        if (this.triggerSave) {
          this.saveData();
      }
    }
  },
  async mounted () {
    // await this.getData();
    this.content = this.model.content;
    this.phone = this.model.phone;
    this.messenger = this.model.messenger;
    this.whatsapp = this.model.whatsapp;
    this.zalo = this.model.zalo;
    this.fanpage = this.model.fanpage;
    this.home_phone = this.model.home_phone;
    this.service_phone = this.model.service_phone;
    this.club_phone = this.model.club_phone;
    this.profile_phone = this.model.profile_phone;
    this.account_phone = this.model.account_phone;
    this.promo_phone =  this.model.promo_phone;
    // this.name = this.model.name;
  },
  async created() {
  },
  methods: {
    getField(col) {
      return col.key;
    },
    getLabel(col) {
      if (col.label) return col.label;
      else return col.name;
    },
    getHeaderStyleView() {
      var style = "display : none";
      return style;
    },
    getDataRows(col, row) {
      var value = '';
      if (col.type == "Text" && row[col.key] && row[col.key].length > 100) {
        var result = row[col.key].substr(0, row[col.key].lastIndexOf(" ", 100)) + "...";
        value = result;
      }
      return value
    },
    clickEdit() {
      this.$router.push({ path: '/admin/list-contact'});
    },
    backToList() {
      this.$router.push({ path: '/admin/list-contact' });
    },
    clickDelete() {
      this.deleteId = this.models.id
      this.deleteName = this.models.fullname
      this.showDeleteDialog = true
    },
    async validateData() {
        this.validationErrors = {};
        
        // let name_add = document.getElementById("name");
        // if (this.name == undefined || this.name == null || this.name == ''){
        //     name_add.setCustomValidity("Tên không được để trống!");
        // }else {
        //     name_add.setCustomValidity("");
        // }
        
        let home_add = document.getElementById("home");
        if (this.home_phone == undefined || this.home_phone == null || this.home_phone == ''){
            home_add.setCustomValidity("Trang chủ không được để trống!");
        }else {
            home_add.setCustomValidity("");
        }
        
        let service_add = document.getElementById("service_add");
        if (this.service_phone == undefined || this.service_phone == null || this.service_phone == ''){
            service_add.setCustomValidity("Dịch vụ không được để trống!");
        }else {
            service_add.setCustomValidity("");
        }
        
        let club_add = document.getElementById("club_add");
        if (this.club_phone == undefined || this.club_phone == null || this.club_phone == ''){
            club_add.setCustomValidity("Câu lạc bộ không được để trống!");
        }else {
            club_add.setCustomValidity("");
        }
        
        let profile_add = document.getElementById("profile_add");
        if (this.profile_phone == undefined || this.profile_phone == null || this.profile_phone == ''){
            profile_add.setCustomValidity("Thông tin cá nhân không được để trống!");
        }else {
            profile_add.setCustomValidity("");
        }
        
        let account_add = document.getElementById("account_add");
        if (this.account_phone == undefined || this.account_phone == null || this.account_phone == ''){
            account_add.setCustomValidity("Tài khoản không được để trống!");
        }else {
            account_add.setCustomValidity("");
        }
        
        let promo_add = document.getElementById("promo_add");
        if (this.promo_phone == undefined || this.promo_phone == null || this.promo_phone == ''){
            promo_add.setCustomValidity("Khuyến mãi không được để trống!");
        }else {
            promo_add.setCustomValidity("");
        }

        var inpObj = document.getElementById("frm_action");
        if (!inpObj.checkValidity()){
            console.log('test=====================');
            inpObj.reportValidity();
            return true;
        }
    },
    handleDataCkditor: function(e,key) {
      var dataContent = (e.length) ? e[0]:'';
      if (key == 'content'){
        this.content = dataContent;
      }
    },
    async saveData() {
      this.$commonFuction.is_loading(true);
      this.$emit('update:triggerSave', false);
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }
      
      if(Object.entries(this.validationErrors).length === 0){
        this.$commonFuction.is_loading(true);
        this.showWarningDialog = false;
        var params = {
            // name: this.name,
            fanpage :this.fanpage,
            phone: this.phone,
            messenger: this.messenger,
            zalo: this.zalo,
            whatsapp: this.whatsapp,
            home_phone: this.home_phone,
            service_phone: this.service_phone,
            club_phone: this.club_phone,
            profile_phone: this.profile_phone,
            account_phone: this.account_phone,
            promo_phone: this.promo_phone,
        }
        this.$emit('save', params);
      }else{
        this.$commonFuction.is_loading(false);
      }
    },
    // async getData() {
    //   var {data} = await this.$apollo.query({
    //     query: DataServices.getList('contact', {"fields" : "id name content"}),
    //     variables: {
    //       where_key: {
    //         deleted: {_eq: false}
    //       }
    //     },
    //     fetchPolicy: "network-only"
    //   })
    //   var model = data[Object.keys(data)];
    //   if (model.length > 0) {
    //     this.models = model[0];
    //     this.name = this.models.name;
    //     this.content = this.models.content;
    //     this.componentKey++;
    //   }
    // }
  },
  apollo: {
  },
  
}
</script>
<style lang="scss">
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.view-action{
  .toolbar-top{background: #fff; padding: 0; border: 0 none;margin-bottom: 16px; padding-bottom: 10px; border-bottom: 1px solid #EEEEEE;
    .manager-title{border: 0 none; padding: 0; margin: 0;}
    .p-toolbar-group-right {
      .p-button{margin-right: 8px; width: auto;
        &:last-child{margin-right: 0;}
      }
    }
  }
  .p-tabview{
    .p-tabview-panels{ padding: 0;}
    .p-tabview-nav{
      li{margin-right: 8px;
        .p-tabview-nav-link{border: 1px solid #B4B4B4; border-radius: 5px 5px 0px 0px !important; background: #eee; font-size: 15px;
          &:hover{text-decoration: none;}
        }
        &.p-highlight{
          .p-tabview-nav-link{background: #00A8FF !important;border: 1px solid #B4B4B4; border-radius: 5px;}
        }
        &:hover{
          .p-tabview-nav-link{background: #00A8FF !important; color: #fff !important;}
        }
      }
    }
  }
  .p-formgrid { margin: 0;
    .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
    .p-card-field{ padding: 24px;
      .p-field{
        .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
        .p-inputtext p-component p-filled{width:500px !important; border-radius: 5px;
          &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px; color: #707070; height: 40px;}
          &.rows-4{height: 80px;}
          .file-select{margin: 0;
            input[type="file"]{display: none;}
            .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
          }
        }
        .box-images{ margin-top: 10px; position: relative;
          .item-img{height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative; border: 0.5px solid #ccc;
            &.p-col-5{width: 18%;;}
            img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain;}
            .button-clear{width: 18px; height: 18px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;
              img{vertical-align: top;}
            }
          }
        }
      }
    }
  }
  .p-rates{
    .top-rates{ padding: 16px;color: #000;border-bottom: 1px solid #B4B4B4; 
      label{margin: 0;}
      span{font-weight: 600; padding-left: 100px;}
    }
    .list-rates{padding: 16px;}
  }
  .table-view >.p-datatable .p-datatable-tbody > tr > td {
    color: #707070 !important;
  }
  .p-inputtext {
    min-height: 40px;
  }
  .p-formgrid .p-card-field .p-field .p-col-width {
    min-height: 40px;
    width: 100% !important;
  }
  .vs__dropdown-option.vs__dropdown-option--selected,
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background-color: #142053 !important;
    color: #ffffff;
  }
  .span-cus{
    color : red;
  }
}
</style>